<template>
  <div data-test="top_menu_circle_button">
    <v-avatar
      v-if="avatar && !hideAvatar"
      :size="size"
      :left="position === 'left'"
      :center="position === 'center'">
      <img
        v-lazy="avatar"
        @error="onError">
    </v-avatar>
    <v-icon
      v-else
      :left="position === 'left'"
      :center="position === 'center'"
      :size="setIconSize"
      :color="getParsedColor">
      mdi-account-circle
    </v-icon>
  </div>
</template>
<script>
import {
  mapActions, mapGetters,
} from 'vuex';
import fileAttempts from '@/mixins/fileAttempts';
export default {
  name: 'AppAvatarImage',
  mixins: [fileAttempts],
  props: {
    hideAvatar: {
      type: Boolean,
      default: false,
    },
    avatar: {
      type: String,
      default: null,
    },
    size: {
      type: Number,
      default: 66,
    },
    color: {
      type: String,
      default: '#2479ff',
    },
    position: {
      type: String,
      default: 'center',
    },
    errorAction: {
      type: String,
      default: 'getAvatar',
    },
    avatarKey: {
      type: String,
      default: '',
    },
    addAdditionalSizeToIcon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('ManageFiles', ['getFullFileByUrl']),
    setIconSize() {
      return Number(this.size) + (this?.addAdditionalSizeToIcon ? 4 : 0);
    },
    getParsedColor() {
      if (this.color) {
        if (this.color[0] == '#') {
          return this.color.substring(0, 7);
        }
        return this.color.substring(0, 6);
      }
      return '';
    },
  },
  methods: {
    ...mapActions('ManageFiles', ['removeFilesFromCache']),
    ...mapActions(['getAvatar']),
    ...mapActions('UserProfile', ['getUserProfile']),
    onError() {
      const key = this.avatarKey;
      this.addAttempts(key);
      if (!this.haveEnoughAttempts(key)) {
        return;
      }
      this.removeFilesFromCache({
        keys: [key],
      });
      if (this.errorAction == 'getAvatar') {
        this.getAvatar({
          key,
          getAvatars: true,
          context: this,
        });
        return;
      }
      if (this.errorAction == 'getUserProfile') {
        this.getUserProfile();
      }
    },
  },
};
</script>

